import Vue from "vue";

export default {
    getBankStatementsAsync: async (clientId, financialYearId, month) => {
        const response = await Vue.axios.get(`bankstatements/${clientId}?fId=${financialYearId}&month=${month}`);
        return response.data;
    },
    getBookingsAsync: async (clientId, financialYearId, month) => {
        const response = await Vue.axios.get(`bankstatements/${clientId}/booking?fId=${financialYearId}&month=${month}`);
        return response.data;
    },
    getConciliationsAsync: async (clientId, financialYearId, month) => {
        const response = await Vue.axios.get(`bankstatements/${clientId}/conciliation?fId=${financialYearId}&month=${month}`);
        return response.data;
    },
    getClients: async () => {
        const response = await Vue.axios.get(`bankstatements/getClients`);
        return response.data;
    },
    getFinancialYears: async clientId => {
        const response = await Vue.axios.get(`bankstatements/getFinancialYears?cId=${clientId}`);
        return response.data;
    },
    getMonths: async (clientId, financialYearId) => {
        const response = await Vue.axios.get(`bankstatements/${clientId}/months?fId=${financialYearId}`);
        return response.data;
    },
    doAutoConciliationAsync: async (clientId, financialYearId, month) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/doAutoConciliation?fId=${financialYearId}&month=${month}`);
        return response.data;
    },
    doCopyToNewAsync: async (clientId, financialYearId) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/doCopyToNew?fId=${financialYearId}`);
        return response.data;
    },
    saveMatchAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/saveMatch?fId=${financialYearId}`, data);
        return response.data;
    },
    removeMatchAsync: async (clientId, financialYearId, recId) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/removeMatch?recId=${recId}&fId=${financialYearId}`);
        return response.data;
    },
    createReceiptsAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/createReceipts?fId=${financialYearId}`, data);
        return response.data;
    },
    createEarningsAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/createEarnings?fId=${financialYearId}`, data);
        return response.data;
    },
    removeReceiptAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/removeReceipt?fId=${financialYearId}`, data);
        return response.data;
    },
    removeEarningAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/removeEarning?fId=${financialYearId}`, data);
        return response.data;
    },
    removeTransactionAsync: async (clientId, financialYearId, data) => {
        const response = await Vue.axios.post(`bankstatements/${clientId}/removeTransaction?fId=${financialYearId}`, data);
        return response.data;
    },
};
